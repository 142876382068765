




















































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Loading,
  Row,
  Col,
  Dialog,
  Button,
} from "vant";
import StatusBar from "@/components/StatusBar.vue";
import { isPhoneNum } from "@/utils/index";
import { SlideCheck } from "@/types";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon,
    [Button.name]: Button,
    StatusBar,
  },
})
export default class Account extends Mixins(Mixin) {
  loading = true;
  public isName: string | false = false;
  public name = "未实名";
  public phone = "";
  public showVer = false;
  public form = {
    phone: "",
    code: "",
    phoneCode: "",
    phoneArea: "",
    token: "",
  };
  public reciveCode = "获取验证码";
  public countdown = {
    num: 60,
    status: false,
  };
  public columns = [
    {
      dictId: "5199110600000000003",
      dictClsId: "1099110500000000059",
      dictCode: "86",
      dictName: "中国大陆",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000004",
      dictClsId: "1099110500000000059",
      dictCode: "852",
      dictName: "中国香港",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000005",
      dictClsId: "1099110500000000059",
      dictCode: "853",
      dictName: "中国澳门",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000006",
      dictClsId: "1099110500000000059",
      dictCode: "886",
      dictName: "中国台湾",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
  ];
  public isFirstSend = true;
  public showPicker = false;
  public phoneColumns: { text: string; key: string }[] = [];
  public checkModel: SlideCheck | undefined = undefined;
  get isPhoneNum(): boolean {
    return isPhoneNum(this.form.phone, this.form.phoneArea);
  }
  mounted(): void {
    this.recieveUerRealName();
  }
  created(): void {
    //手机号区号
    this.columns.forEach((item) => {
      this.phoneColumns.push({
        text: `${item.dictName} +${item.dictCode}`,
        key: item.dictCode,
      });
    });
  }
  recieveUerRealName(): void {
    let reg = /(\d{3})\d{4}(\d{4})/;
    this.$api.memberApi.setting.getAccountInfo(({ data }) => {
      console.log(data);
      this.name = data.authentication ? "已实名" : "未实名";
      if (this.AppSource == "harmonyOS") {
        this.UserModule.REALNAME_PERSIST(data.authentication!);
        this.UserModule.SET_HarmonyOS_UserPhone_PERSIST(data.phone!);
      }
      this.phone = data.phone ? data.phone.replace(reg, "$1****$2") : "未绑定";
      this.isName = data.wechat ? data.wechat : false;
      this.loading = false;
      this.form.phone = data.phone ? data.phone : "";
      this.form.phoneArea = data.phoneArea ? data.phoneArea : "";
    });
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  /*
   *实名认证
   */
  goRealName(): void {
    this.$router.push(`/personal-data/real-name/${1}`);
  }
  /*
   *切换电话
   */
  changePhone(): void {
    this.showVer = true;
    // this.$router.push("/personal-data/change-phone");
  }
  // 手机号验证规则
  checkPhoneNum(): boolean {
    if (this.isPhoneNum) {
      return true;
    } else {
      if (!this.form.phone) {
        this.$toast("手机号输入不能为空");
        return false;
      } else {
        this.$toast("请输入正确的手机号");
        return false;
      }
    }
  }
  // 发送短信验证码
  sendCodeMessage(): void {
    if (this.reciveCode == "再次获取") {
      this.form.phoneCode = "";
    }
    //首先进行手机号校验
    this.checkPhoneNum();
    if (this.checkPhoneNum()) {
      let phoneCaptchaRequest: member.PhoneCaptchaRequest = {
        account: this.form.phone as string,
        phoneArea: ("+" + this.form.phoneArea) as string,
        sessionId: "",
        sig: "",
        token: "",
      };
      // sessionId: (this.checkModel as SlideCheck).sessionId,
      // sig: (this.checkModel as SlideCheck).sig,
      // token: (this.checkModel as SlideCheck).token,
      this.$api.memberApi.setting.getPhoneCaptchaNoSlide(
        phoneCaptchaRequest,
        (data) => {
          return data;
        }
      );
      if (this.isFirstSend == false) {
        this.reciveCode = "再次获取";
      }
      this.$toast("验证码已发送，请注意查收！");
      this.countDown();
    }
  }
  // 修改手机区号
  onConfirm(value: { key: string }): void {
    this.form.phoneArea = value.key;
    this.changePickerStatus();
  }
  // 手机区号选择器打开&关闭
  changePickerStatus(): void {
    this.showPicker = !this.showPicker;
  }
  // 验证码发送倒计时
  countDown(): void {
    if (this.countdown.num === 0) {
      this.countdown.status = false;
      this.countdown.num = 60;
      return;
    }
    this.countdown.status = true;
    this.countdown.num--;

    setTimeout(() => {
      this.countDown();
    }, 1000);
  }

  // 验证原来手机号
  confirm(): void {
    this.$api.memberApi.setting.checkCaptcha(
      {
        captcha: this.form.phoneCode,
        phone: this.form.phone,
      },
      (data) => {
        if (data) {
          this.$router.push("/personal-data/change-phone");
        }
      }
    );
  }
}
