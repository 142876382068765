





















































































import { Component, Watch, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  NavBar,
  Field,
  Icon,
  Dialog,
  Popup,
  Picker,
} from "vant";
import { isPhoneNum } from "@/utils/index";
import { SlideCheck } from "@/types";
@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
})
export default class PhoneNumberBond extends Mixins(Mixin) {
  public isFirstSend = true;
  public loginDisabled = true;
  public reciveCode = "获取验证码";
  public hideshow = true; //显示或隐藏footer
  public docmHeight = document.documentElement.clientHeight; //默认屏幕高度
  public showHeight = document.documentElement.clientHeight; //实时屏幕高度
  public form = {
    phone: "",
    code: "",
    phoneCode: "",
    phoneArea: "86",
    token: "",
  };
  public showPicker = false;
  public phoneColumns: { text: string; key: string }[] = [];

  public columns = [
    {
      dictId: "5199110600000000003",
      dictClsId: "1099110500000000059",
      dictCode: "86",
      dictName: "中国大陆",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000004",
      dictClsId: "1099110500000000059",
      dictCode: "852",
      dictName: "中国香港",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000005",
      dictClsId: "1099110500000000059",
      dictCode: "853",
      dictName: "中国澳门",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
    {
      dictId: "5199110600000000006",
      dictClsId: "1099110500000000059",
      dictCode: "886",
      dictName: "中国台湾",
      dictDesp: "",
      showNo: null,
      isSystem: null,
    },
  ];
  public countdown = {
    num: 60,
    status: false,
  };
  public redirect = "/mine";
  get isPhoneNum(): boolean {
    return isPhoneNum(this.form.phone, this.form.phoneArea);
  }
  @Watch("form.phoneCode")
  cloginDisabled(): void {
    this.$emit("phoneCodeChange", this.form.phoneCode);
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }
  @Watch("form.phone")
  changePhone(): void {
    this.$emit("phoneChange", this.form.phone);
    if (this.form.phoneCode) {
      if (this.isPhoneNum && this.form.phoneCode.length == 6) {
        this.loginDisabled = false;
      } else {
        this.loginDisabled = true;
      }
    } else {
      this.loginDisabled = true;
    }
  }

  mounted(): void {
    // window.onresize监听页面高度的变化,防止底部图标上来
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
        if (this.docmHeight > this.showHeight) {
          this.hideshow = false;
        } else {
          this.hideshow = true;
        }
      })();
    };
  }

  created(): void {
    //手机号区号
    this.columns.forEach((item) => {
      this.phoneColumns.push({
        text: `${item.dictName} +${item.dictCode}`,
        key: item.dictCode,
      });
    });
    //上次登录存的手机号自动填充--->这块不需要了
    // this.form.phone = !localStorage.getItem("lastPhoneNum")
    //   ? ""
    //   : JSON.parse(JSON.stringify(localStorage.getItem("lastPhoneNum")));
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  closeLogin(): void {
    this.$router.go(-1);
  }
  // 修改手机区号
  onConfirm(value: { key: string }): void {
    this.form.phoneArea = value.key;
    this.changePickerStatus();
  }
  // 手机区号选择器打开&关闭
  changePickerStatus(): void {
    this.showPicker = !this.showPicker;
  }
  // 手机号验证规则
  checkPhoneNum(): boolean {
    if (this.isPhoneNum) {
      return true;
    } else {
      if (!this.form.phone) {
        this.$toast("手机号输入不能为空");
        return false;
      } else {
        this.$toast("请输入正确的手机号");
        return false;
      }
    }
  }

  checkPhoneCode(): boolean {
    if (this.form.phoneCode) {
      if (this.form.phoneCode.length == 6) {
        return true;
      } else {
        this.$toast("请输入正确的验证码");
        return false;
      }
    } else {
      this.$toast("请输入正确的验证码");
      return false;
    }
  }

  // 发送短信验证码
  sendCodeMessage(): void {
    if (this.reciveCode == "再次获取") {
      this.form.phoneCode = "";
    }
    //首先进行手机号校验
    this.checkPhoneNum();
    if (this.checkPhoneNum()) {
      if (this.checkPhoneNum()) {
        let phoneCaptchaRequest = {
          account: this.form.phone,
          phoneArea: "+" + this.form.phoneArea,
          sessionId: "",
          sig: "",
          token: "",
        };
        this.$api.memberApi.setting.getPhoneCaptcha(
          phoneCaptchaRequest,
          (data) => {
            return data;
          }
        );
        if (this.isFirstSend == false) {
          this.reciveCode = "再次获取";
        }
        this.$toast("验证码已发送，请注意查收！");
        this.countDown();
      }
    }
  }
  // 验证码发送倒计时
  countDown(): void {
    if (this.countdown.num === 0) {
      this.countdown.status = false;
      this.countdown.num = 60;
      return;
    }
    this.countdown.status = true;
    this.countdown.num--;

    setTimeout(() => {
      this.countDown();
    }, 1000);
  }

  //绑定
  submitLogin(): void {
    // let socialLoginBindDTO = {
    //   /** 渠道 */
    //   channel: "MP",
    //   /** 验证码 */
    //   code: this.form.phoneCode,
    //   /** 手机号 */
    //   mobile: this.form.phone,
    //   /** oauth2AccessToken */
    //   oauth2AccessToken: {},
    //   /** openId,用户未绑定时返回 */
    //   openId: "",
    //   /** openid */
    //   openid: "",
    //   /** 区号 */
    //   phoneArea: this.form.phoneArea,
    //   /** unionid */
    //   unionid: "",
    // };
    // this.$api.oauth2Api.socialLogin.bind(socialLoginBindDTO, (data) => {
    //   console.log(socialLoginBindDTO, "绑定信息");
    // });
  }
}
